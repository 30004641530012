import axios from "@/utils/request";

// 获取裸聊列表
export function queryNudeChat(data) {
  return axios.get(`/nude/chat`, {
    params: data
  });
}

// 获取裸聊详情
export function queryNudChatDetail(data) {
  return axios.get(`/nude/chat/detail`, {
    params: data
  });
}

// 裸聊举报
export function nudeChatReport(data) {
  return axios.post(`/nude/chat/feedback`, data);
}

// 连线裸聊
export function buyNudeChat(data) {
  return axios.post(`/nude/chat/buy`, data);
}